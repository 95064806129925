import Rule from '@/models/Rule';

export default class RuleRepository {
    public static getAll() {
        return Rule.all();
    }

    public static getById(id: string) {
        return Rule.query()
            .whereId(id)
            .withAllRecursive()
            .first();
    }
}
