import Schedule from '@/models/Schedule';

export default class ScheduleRepository {
    public static getAll() {
        return Schedule.query()
            .withAll()
            .get();
    }

    public static getById(id: string) {
        return Schedule.query()
            .whereId(id)
            .withAll()
            .first();
    }
}
