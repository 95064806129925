import Resource from '@/models/Resource';

export default class ResourceRepository {
    public static getAll() {
        return Resource.query()
            .withAll()
            .get();
    }

    public static getById(id: string) {
        return Resource.query()
            .whereId(id)
            .withAll()
            .first();
    }
}
