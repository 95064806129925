
import { Component, Vue } from 'vue-property-decorator';
import { Form, Spin } from 'ant-design-vue';
import CMSUserRepository from '@/repositories/CMSUserRepository';
import CMSUser from '@/models/CMSUser';
import Schedule from '@/models/Schedule';
import Resource from '@/models/Resource';
import ResourceRepository from '@/repositories/ResourceRepository';
import moment from 'moment';
import RecurrenceRule from './RecurrenceRule.vue';
import Rrule from '@/models/interfaces/Rrule';
import { RouteNames } from '@/enums/routes/RouteNames';
import ScheduleRepository from '@/repositories/ScheduleRepository';
import RuleRepository from '@/repositories/RuleRepository';
import Rule from '@/models/Rule';

@Component({
    name: 'CalendarScheduleEdit',
    components: {
        Form,
        Spin,
        FormItem: Form.Item,
        RecurrenceRule,
    },
})
export default class CalendarScheduleEdit extends Vue {
    private moment = moment;
    private id: string | null = null;
    private type = 'user';
    private itemData: Schedule = {} as Schedule;
    private rrule: Rrule = {} as Rrule;
    private rruleStr: string = '';
    private isLoading: boolean = false;

    private get resources() {
        return ResourceRepository.getAll();
    }

    private get users() {
        return CMSUserRepository.getAll();
    }

    private async getCalendarScheduleData() {
        try {
            if (!this.id) {
                return;
            }
            await Schedule.getById(this.id);
            this.itemData = ScheduleRepository.getById(this.id) as Schedule;

            await Rule.getById(this.itemData.ruleId);

            // @ts-ignore
            this.rrule = RuleRepository.getById(this.itemData.ruleId) as Rrule;

            if (this.rrule) {
                this.rrule.startDateTime = this.formatFromNumberDateToMoment(this.itemData.dateTimeFrom);
                this.rrule.endDateTime = this.formatFromNumberDateToMoment(this.itemData.dateTimeTo);
                // .add(
                //     this.itemData.duration,
                //     'milliseconds'
                // );

                this.rruleStr = this.itemData.recurrenceExpression ? this.itemData.recurrenceExpression : this.rruleStr;
            }
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
        }
    }

    private formatFromNumberDateToMoment(date: number) {
        const formattedDate = new Date(Number(date));

        return this.moment(formattedDate);
    }
    private async addCalendarSchedule() {
        if (!this.rrule || !this.itemData) {
            return;
        }

        const dateTimeFrom = this.moment(this.rrule.startDateTime);
        const dateTimeTo = this.moment(this.rrule.endDateTime);

        const duration = this.moment.duration(dateTimeTo.diff(dateTimeFrom));
        const durationInMs = duration.asMilliseconds();

        try {
            // TO DO: We need to post rrule on separate endpoint
            // @ts-ignore
            const newRule = (await Rule.createNew(this.rrule)) as AxiosResponse;

            if (newRule.status === 200) {
                this.itemData.ruleId = newRule.data.id;
            }

            const data = {
                ...this.itemData,
                dateTimeTo: dateTimeTo.valueOf(),
                dateTimeFrom: dateTimeFrom.valueOf(),
                recurrenceExpression: this.rruleStr,
                duration: durationInMs,
            } as any;

            await Schedule.createNew(data);

            this.$notification.success({
                message: this.$t('Promjene uspješne!') as string,
                description: '',
            });
            await this.$router.push({
                name: RouteNames.schedulesResources,
                params: { dynamic: 'CalendarSchedules' },
            });
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
        }
    }

    private async updateCalendarSchedule() {
        if (this.id && this.rrule && this.itemData) {
            try {
                const dateTimeFrom = this.moment(this.rrule.startDateTime);
                const dateTimeTo = this.moment(this.rrule.endDateTime);

                const duration = this.moment.duration(dateTimeTo.diff(dateTimeFrom));
                const durationInMs = duration.asMilliseconds();

                await Rule.updateExisting(String(this.rrule.id), this.rrule);

                const data = {
                    ...this.itemData,
                    dateTimeTo: dateTimeTo.valueOf(),
                    dateTimeFrom: dateTimeFrom.valueOf(),
                    recurrenceExpression: this.rruleStr,
                    duration: durationInMs,
                } as any;

                await Schedule.updateExisting(this.id, data);
                this.$notification.success({
                    message: this.$t('Promjene uspješne!') as string,
                    description: '',
                });
                await this.$router.push({
                    name: RouteNames.schedulesResources,
                    params: { dynamic: 'CalendarSchedules' },
                });
            } catch (e) {
                this.$notification.error({
                    message: this.$t('Dogodila se greška') as string,
                    description: (e as Error).message,
                });
            }
        }
    }

    private async mounted() {
        this.id = this.$route.params.id ?? null;
        this.type = (this.$route.query.type as string | null) ?? 'user';

        try {
            this.isLoading = true;
            await Promise.all([CMSUser.getAll(), Resource.getAll()]);
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
        } finally {
            this.isLoading = false;
        }

        if (this.$route.params.userId) {
            // @ts-ignore
            this.itemData.userId = Number(this.$route.params.userId) ?? null;
        }

        if (this.id) {
            await this.getCalendarScheduleData();
        }
    }
}
